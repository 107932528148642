import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showMessage: false,
    loadingShow: false,
    url: "",
    loginShow: false,
    registerShow: false,
    recoverShow: false,
		gamelist:[],
    isExpand:localStorage.getItem('isExpand') === 'true',
  },
  getters: {},
  mutations: {
		setGamelist(state, list) {
			state.gamelist = list
		},
    setLoadingShowTrue(state) {
      state.loadingShow = true;
    },
    setLoadingShowFalse(state) {
      state.loadingShow = false;
    },
    setLoginShow(state,value){
      state.loginShow = value;
    },
    setRegisterShow(state,value){
      state.registerShow = value;
    },
    setRecoverShow(state,value){
      state.recoverShow = value;
    },
    setUrl(state, url) {
      state.url = url;
    },
    getUrl(state) {
      return state.url;
    },

    getIsExpand(state){
      return state.isExpand;
    },
    setIsExpand(state){
      state.isExpand  = !state.isExpand;
      localStorage.setItem('isExpand',state.isExpand);
    },


    goService(state){
      state.loadingShow = true;
      setTimeout(()=>{
        state.loadingShow = false;
      },3000)
      
      window.open("https://secure.livechatinc.com/licence/18398763/open_chat.cgi",'_blank')

    },
  },
  actions: {},
  modules: {},
});
