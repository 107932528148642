<template>
    <el-dialog class="loginDialog" :visible="show" @update:visible="upLoginShow" :show-close="false">
        <div class="loginContent">
            <div class="loginLeft"></div>
            <div class="loginRight">
                <div class="loginTitle">
                    <div class="titles">登录账号</div>
                    <img src="@/assets/home/close.svg" @click="upLoginShow(false)" />
                </div>
                <div class="intDemo">
                    <img class="leftIcon" src="@/assets/login/usernameIcon.svg" />
                    <el-input class="usernameInt" v-model="username" placeholder="登入账号"></el-input>
                </div>

                <div class="intDemo">
                    <div class="pwdIcon">
                        <img class="leftIcon " src="@/assets/login/porswordIcon.svg" />
                    </div>
                    <el-input class="usernameInt" v-model="porsword" :type="isPwd ? 'text' : 'password'"
                        placeholder="密码" clearable></el-input>
                    <img class="eyes" src="@/assets/personal/closeEye.svg" v-show="isPwd" @click="isPwd = !isPwd" />
                    <img class="eyes" src="@/assets/personal/openEye.svg" v-show="!isPwd" @click="isPwd = !isPwd" />
                </div>
                <div class="flex-ju">
                    <div class="flex-left" @click="setUser = !setUser">
                        <img v-show="!setUser" src="@/assets/login/round.svg" />
                        <img v-show="setUser" src="@/assets/login/round-dg.svg" />
                        记住我的账号
                    </div>
                    <div class="flex-right" @click="forgetPwd">
                        忘记密码?
                    </div>
                </div>
                <van-button class="btn" :loading="subLoading" :disabled="subLoading" @click="submit">登入</van-button>
                <van-button class="btn register" @click="goRigister">注册</van-button>

                <div class="dibu">
                    <div class="titles">我们推荐使用以下浏览器以获得最佳使用体验</div>
                    <div class="imgDemo">
                        <img src="@/assets/login/google.svg" @click="goGoogle(0)" />
                        <img src="@/assets/login/safari.svg" @click="goGoogle(1)" />
                        <img src="@/assets/login/frame.svg" @click="goGoogle(2)" />
                    </div>
                </div>
                <div class="encounter">
                    遇到问题？联系<img src="@/assets/login/service.svg" /><span>在线客服</span>
                </div>
            </div>
        </div>

    </el-dialog>
</template>

<script>
import { sysLogin } from '@/request/api'
export default {
    name: "loginPop",
    props: {
        show: Boolean,
    },
    data() {
        return {
            username: "",
            porsword: "",
            isPwd: false,
            setUser: false,
            subLoading: false,
        }
    },
    created() {
        if (localStorage.getItem('userPadaLogin')) {
            this.username = localStorage.getItem('userPadaLogin')
        }
    },
    methods: {
        goRigister() {
            this.$emit("goRigister")
        },
        forgetPwd() {
            this.$emit('forgetPwd')
        },
        upLoginShow(newValue) {
            this.$emit('update:show', newValue);
        },
        goGoogle(index) {
            switch (index) {
                case 0:
                    window.open('https://www.google.com/chrome/', '_blank')
                    break;
                case 1:
                    window.open('https://support.apple.com/zh-cn/docs', '_blank')
                    break;
                case 2:
                    window.open('https://www.mozilla.org/en-US/', '_blank')
                    break;

                default:
                    break;
            }


        },
        submit() {
            var regPwd = /^(?=.*[0-9])(?=.*[a-zA-Z])[0-9a-zA-Z]{6,18}$/;
            if (!/^[a-zA-Z0-9]+$/.test(this.username)) {
                this.$message.error('用户名只能包含字母和数字');
                return;
            }
            if (!regPwd.test(this.porsword)) {
                this.$message.error('密码为6-18位且包含字母和数字');
                return;
            }

            this.subLoading = true;
            var opt = {
                username: this.username,
                password: this.porsword,
            }
            sysLogin(opt).then((res) => {
                if (res.code == 0) {
                    localStorage.setItem('token', res.data)
                    this.$message({
                        message: '登入成功',
                        type: 'success'
                    });
                    this.upLoginShow(false)
                    this.$router.go(0)
                    if (this.setUser) {
                        localStorage.setItem('userPadaLogin', this.username)
                    }
                }
                this.subLoading = false;
            });
        },
    }
}
</script>

<style lang="less" scoped>
.loginDialog {

    /deep/.el-dialog {
        border-radius: 10px;
        width: 900px;

        .el-dialog__header {
            display: none;
        }

        .el-dialog__body {
            border-radius: 10px;
            padding: 0;
        }
    }


    .loginContent {
        border-radius: 10px;
        display: flex;
        align-items: center;

        .loginLeft {
            border-radius: 10px 0 0 10px;
            height: 577px;
            align-self: stretch;
            background: #D9D9D9;
            width: 350px;
        }

        .loginRight {
            width: 550px;
            padding: 20px;
            height: 577px;
            border-radius: 0 8px 8px 0;
            background: radial-gradient(133.65% 137.5% at -19.87% -5.42%, #02568E 6%, #13293B 61.18%, #152B3F 100%);

            .loginTitle {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .titles {
                    color: #FFF;
                    font-family: "PingFang SC";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                }

                img {
                    width: 40px;
                    height: 40px;
                }
            }

            .intDemo {
                display: flex;
                align-items: center;
                border-radius: 8px;
                border: 1px solid rgba(255, 255, 255, 0.04);
                background: rgba(255, 255, 255, 0.04);
                padding: 12px;
                margin-top: 22px;

                .leftIcon {
                    width: 32px;
                    height: 32px;
                    margin-right: 10px;
                }

                .pwdIcon {
                    margin-right: 5px;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .leftIcon {
                        margin-right: 0;
                        width: 20.4px;
                        height: 24px;
                        flex-shrink: 0;
                    }

                }

                .usernameInt {
                    background: transparent;

                    /deep/.el-input__inner {
                        background-color: transparent;
                        border: 0;
                        height: 24px;
                        color: #fff;
                        font-size: 16px;
                    }

                    /deep/.el-input__inner::placeholder {
                        font-size: 16px;
                        user-select: none;
                        color: rgba(255, 255, 255, 0.45);
                    }

                }

                /deep/.el-input__suffix {
                    top: -6px;
                }

                .eyes {
                    width: 16.667px;
                    height: 14.167px;
                    flex-shrink: 0;
                }
            }

            .flex-ju {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin: 14px auto;
                user-select: none;

                .flex-left {
                    display: flex;
                    align-items: center;
                    color: rgba(255, 255, 255, 0.65);
                    cursor: pointer;
                    /* 内容/M-大多数文字 */
                    font-family: "PingFang SC";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;

                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                    }
                }

                .flex-right {
                    color: rgba(255, 255, 255, 0.65);
                    cursor: pointer;
                    /* 内容/M-大多数文字 */
                    font-family: "PingFang SC";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;

                }
            }

            .btn {
                width: 100%;
                border: 0;
                display: flex;
                height: 52px;
                padding: 4px 12px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                align-self: stretch;
                border-radius: 8px;
                background: linear-gradient(92deg, #0385FF 0%, #0359FF 100%);
                color: #FFF;
                text-align: center;
                font-family: "PingFang SC";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;

            }

            .register {
                border-radius: 8px;
                background: rgba(255, 255, 255, 0.09);
                margin: 24px 0 14px 0;
            }

            .dibu {
                user-select: none;
                width: 100%;
                margin: 0 auto 0 auto;

                .titles {
                    color: rgba(255, 255, 255, 0.65);

                    /* 内容/M-大多数文字 */
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    margin-bottom: 20px;
                }

                .imgDemo {
                    display: flex;
                    align-items: center;
                    margin: 20px auto 0 auto;
                    width: 60%;
                    justify-content: center;
                    gap: 60px;

                    img {
                        width: 44px;
                        height: 63px;
                    }
                }
            }

            .encounter {
                display: flex;
                align-items: center;
                white-space: nowrap;
                justify-content: center;
                margin-top: 14px;

                img {
                    margin: 0 5px 0 10px;
                }

                span {
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    background: linear-gradient(180deg, #FFF -54.54%, #C4DEFF 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

        }
    }
}
</style>