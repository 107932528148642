<template>
    <div class="footerContent" :class="{ 'paddingBottom': !noBom  }">
        <div class="fwtk">
            <div @click="$router.push('/terms?tab=0')">服务条款</div>
            <div @click="$router.push('/about')">关于我们</div>
            <div>联系我们</div>
            <div @click="$router.push('/download')">下载APP</div>
        </div>
        <div class="twoDemo">
            <div class="twoTit">游戏提供商</div>
            <img class="hzhb" src="@/assets/home/hzhb.webp" draggable="false"/>
        </div>
       
        <div class="dbdemo">
            <div class="dbLeft">
                <div class="dbTit">合作伙伴</div>
                <div class="dbImg">
                    <img class="flba" src="@/assets/home/home-hzhb.png" draggable="false"/>
                </div>

            </div>
            <div class="dbLeft">
                <div class="dbTit">资质认证</div>
                <div class="dbImg">
                    <img class="guracao" src="@/assets/home/home-curacao.png" draggable="false" />
                </div>
            </div>
        </div>

        

        <div class="logoDemo">
            <div class="logos">
                <img src="@/assets/head/logo.webp" draggable="false"/>
            </div>
            <div class="copyright">COPYRIGHT © 2024 PANDA集团版权所有</div>
            <div class="jsDemo">
                PANDA由MediumRareN.V.所属和经营，注册编号：145353，注册地址：Korporaalweg10,WillemstadCuracao。请通过pd520@gmail.com与我们联系。支付代理公司是Medium RareLimited，地址于7-9RigaFeraiou,LIZANTIACOURT,Office310,Agioi Omologites,1087Nicosia,Cyprus以及注册号：HE410775
            </div>
            <div class="ybzzss">
                <img src="@/assets/home/ybzdss.webp" draggable="false"/>
            </div>
        </div>

        
    </div>
</template>

<script>
export default {
    name: "footerIndex",
    props:{
        noBom:Boolean,
    }
}
</script>

<style lang="less" scoped>
.paddingBottom{
    padding-bottom: 129px;
}
.footerContent {
    width: 100%;
    padding: 0 44px 32px;
    min-height: 233px;
   
    .dbdemo {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 24px auto 0 auto;
        justify-content: center;
        padding-left: 20px;
        gap: 48px;
        padding-bottom: 24px;
        border-bottom: 1px solid rgba(26, 44, 56, 1);
        .dbLeft {
            width: 172px;

            .dbTit {
                text-align: left;
                color: #FFF;
                font-family: "PingFang SC";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                /* 14px */
            }

            .dbImg {
                margin-top: 11px;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .flba {
                    width: 102px;
                    height: 48px;
                }

                .guracao {
                    width: 114px;
                    height: 42px;
                }
            }
        }
    }
    
    

  .fwtk {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    height: 71px;
    gap: 48px;
    border-bottom: 1px solid rgba(26, 44, 56, 1);
    div {
        color: rgba(255, 255, 255, 1);
  font-family: "PingFang SC";
  font-weight: 400;
  font-size: 16px;
     
    }
  }
  .twoDemo{
    width: 100%;
    border-bottom: 1px solid rgba(26, 44, 56, 1);
    padding-top: 24px;
    padding-bottom: 23px;
    .twoTit{
        text-align: left;
        color: rgba(255, 255, 255, 1);
  font-family: "PingFang SC";
  font-weight: 400;
  font-size: 16px;
    }
    .hzhb {
    width: 100%;
    margin: 20px auto 0 auto;
    height: 38px;
  }
  }

  
  
  .logoDemo{
    width: 100%;
    padding: 24px 0 0;
    .logos{
        width: 116px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 15px auto;
  img{
    width: 100%;
    height: 100%;
  }
    }
    .copyright {
    text-align: center;
    width: 89.3333%;
    margin: 0 auto;
    color: rgba(177, 186, 211, 1);
    font-family: "PingFang SC";
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    /* 12px */
  }
  .jsDemo{
    text-align: center;
  color: rgba(179, 185, 210, 1);
  font-family: "PingFang SC";
  font-weight: 400;
  font-size: 12px;
  margin: 24px  0;
  line-height: 200%;
  }
  .ybzzss{
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 100%;
        height: 100%;
    }
  }
  }
}
</style>