<template>
  <div class="leftHead" :style="{ width: isExpand ? '240px' : '60px' }">
    <div
      class="headTop"
      :style="{
        gap: isExpand ? '10px' : '0px',
      }"
    >
      <div class="threeTraverse" @click="expand()">
        <img src="@/assets/newHead/three.webp" draggable="false" />
      </div>
      <div class="ylc" @click="$router.push('/')" v-show="isExpand">
        <img
          :src="
            require($route.path == '/'
              ? `@/assets/newHead/top/ylc1.webp`
              : `@/assets/newHead/top/ylc0.webp`)
          "
          draggable="false"
        />
      </div>
      <div class="ylc tycs" v-show="isExpand">
        <img src="@/assets/newHead/top/ty0.webp" draggable="false" />
        <div class="popzwkf"><img src="@/assets/newHead/zwkf.webp" draggable="false"  /></div>
      </div>
    </div>

    <div class="leftBom" :class="{ wide: isExpand, narrow: !isExpand }">
      <div class="myHead">
        <div class="heads">
          <div class="avatar">
            <img
              :src="userData.headImg"
              v-if="userType"
              v-show="isLogin"
              draggable="false"
            />
          </div>
          <div class="liveDemo" v-show="isExpand && isLogin">
            {{ `Lv.${myData.currLevel || 0}` }}
          </div>
        </div>
        <div class="myName" v-show="isExpand">{{ userData.username }}</div>
        <div class="myLine" v-show="isExpand">
          <div class="starts" v-show="isLogin">
            <img src="@/assets/newHead/start.webp" draggable="false" />
          </div>
          <div v-show="isLogin">这是您加入的第{{ userData.registerDay }}天</div>
        </div>
      </div>

      <div class="homeAndSport" v-show="!isExpand">
        <div class="smallYlc">
          <img
            :src="
              require($route.path == '/'
                ? `@/assets/newHead/top/smylc1.webp`
                : `@/assets/newHead/top/smylc0.webp`)
            "
          />
        </div>
        <div class="smallYlc smallTy">
          <img src="@/assets/newHead/top/smty0.webp" draggable="false" />
          <div class="popzwkf"><img src="@/assets/newHead/zwkf.webp" draggable="false"  /></div>
        </div>
      </div>

      <div class="listOut" v-for="(item, index) in sidebarList" :key="index">
        <div
          class="listLine"
          v-for="(child, y) in item.chlidren"
          :key="index + y + 100"
          :class="{ selectSid: $route.path == child.path }"
          @click="goLink(child)"
        >
          <div class="listLeftImg">
            <img
              :src="$route.path == child.path ? child.icons : child.icon"
              draggable="false"
            />
          </div>
          <div class="listName" v-show="isExpand">{{ child.name }}</div>
          <div class="infoNum" v-show="child.id == 8 && isExpand && noReadCount > 0">
            {{ noReadCount + "+" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { userInfo, userHome } from "@/request/api";
export default {
  name: "leftHead",
  data() {
    return {
      userData: {
        username: "游客",
      },
      sidebarList: [
        {
          chlidren: [
            {
              name: "存款",
              icon: require("@/assets/newHead/list/0.webp"),
              icons: require("@/assets/newHead/list/yes0.webp"),
              id: 1,
              path: "/deposit",
            },
            {
              name: "取款",
              icon: require("@/assets/newHead/list/1.webp"),
              icons: require("@/assets/newHead/list/yes1.webp"),
              id: 2,
              path: "/withdrawals",
            },
            {
              name: "转账",
              icon: require("@/assets/newHead/list/2.webp"),
              icons: require("@/assets/newHead/list/yes2.webp"),
              id: 3,
              path: "/convert",
            },
          ],
        },
        {
          chlidren: [
            {
              name: "个人中心",
              icon: require("@/assets/newHead/list/3.webp"),
              icons: require("@/assets/newHead/list/yes3.webp"),
              id: 4,
              path: "/personal",
            },
            {
              name: "交易记录",
              icon: require("@/assets/newHead/list/4.webp"),
              icons: require("@/assets/newHead/list/yes4.webp"),
              id: 5,
              path: "/record",
            },
            {
              name: "投注记录",
              icon: require("@/assets/newHead/list/5.webp"),
              icons: require("@/assets/newHead/list/yes5.webp"),
              id: 6,
              path: "/record/postRecord",
            },
            {
              name: "安全中心",
              icon: require("@/assets/newHead/list/6.webp"),
              icons: require("@/assets/newHead/list/yes6.webp"),
              id: 7,
              path: "/setup",
            },
            {
              name: "我的消息",
              icon: require("@/assets/newHead/list/7.webp"),
              icons: require("@/assets/newHead/list/yes7.webp"),
              id: 8,
              path: "/info/index",
            },
          ],
        },
        {
          chlidren: [
            {
              name: "游戏提供商",
              icon: require("@/assets/newHead/list/8.webp"),
              icons: require("@/assets/newHead/list/yes8.webp"),
              id: 9,
              path: "/",
            },
          ],
        },
        {
          chlidren: [
            {
              name: "促销活动",
              icon: require("@/assets/newHead/list/9.webp"),
              icons: require("@/assets/newHead/list/yes9.webp"),
              id: 10,
              path: "/details",
            },
            {
              name: "邀请好友",
              icon: require("@/assets/newHead/list/10.webp"),
              icons: require("@/assets/newHead/list/yes10.webp"),
              id: 11,
              path: "/share/index",
            },
            {
              name: "福利中心",
              icon: require("@/assets/newHead/list/11.webp"),
              icons: require("@/assets/newHead/list/yes11.webp"),
              id: 12,
              path: "/welfare/index",
            },
            {
              name: "VIP中心",
              icon: require("@/assets/newHead/list/12.webp"),
              icons: require("@/assets/newHead/list/yes12.webp"),
              id: 13,
              path: "/vip",
            },
          ],
        },
        {
          chlidren: [
            {
              name: "在线客服",
              icon: require("@/assets/newHead/list/13.webp"),
              icons: require("@/assets/newHead/list/yes13.webp"),
              id: 14,
              path: "/zxkf",
            },
            {
              name: "下载APP",
              icon: require("@/assets/newHead/list/14.webp"),
              icons: require("@/assets/newHead/list/yes14.webp"),
              id: 15,
              path: "/download",
            },
          ],
        },
      ],
      isLogin: false,
      myData: {},
      userType: false,
      noReadCount:0,
    };
  },
  computed: {
    ...mapState({
      isExpand: (state) => state.isExpand,
    }),
  },
  created() {
    this.isLogin = localStorage.getItem("token");
    if (this.isLogin) {
      this.getUserInfo();
      this.getUserHome();
    }
  },
  beforeDestroy() {},
  methods: {
    expand() {
      this.$store.commit("setIsExpand");
    },
    goLink(item) {
      if (item.id == 14) {
        this.$store.commit("goService");
      } else {
        this.$router.push(item.path);
      }
    },
    getUserInfo() {
      this.getUserLoading = true;
      userInfo().then((res) => {
        if (res.code == 0) {
          var balan = res.data.balance.toFixed(2);
          var dotIndex = balan.indexOf(".");
          if (dotIndex == -1) {
            res.data.balance = `${res.data.balance}.00`;
          }
          this.balanceOne = balan.slice(0, dotIndex + 1);
          this.balanceTwo = balan.slice(dotIndex + 1);
          this.userData = res.data;
          this.userType = true;
        }
        this.getUserLoading = false;
      });
    },
    getUserHome() {
      userHome().then((res) => {
        if (res.code == 0) {
          this.noReadCount = res.data.noReadCount;
          this.waitReceiveCount = res.data.waitReceiveCount;
          this.myData = res.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.leftHead {
  height: 100dvh;
  transition: all 0.2s ease;
  background: #0f212e;

  .headTop {
    width: 100%;
    transition: all 0.2s ease;
    height: 60px;
    background: rgba(16, 27, 31, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .threeTraverse {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .ylc {
      width: 78px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .leftBom {
    width: 100%;
    height: calc(100dvh - 60px);
    transition: all 0.2s ease;

    .myHead {
      position: relative;
      overflow: hidden;
      width: 100%;
      border-radius: 4px;
      box-sizing: border-box;

      .heads {
        position: relative;

        .avatar {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
        }

        .liveDemo {
          width: 61px;
          height: 19px;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -9px;
          margin: auto;
          background: url("@/assets/newHead/live.webp") no-repeat 100% 100%;
          background-size: 100% 100%;
          font-size: 12px;
          line-height: 19px;
        }
      }

      .myName {
        color: rgba(255, 255, 255, 1);
        font-family: DIN;
        font-weight: 700;
        font-size: 16px;
        margin-top: 18px;
      }

      .myLine {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        color: rgba(255, 255, 255, 1);
        font-family: "PingFang SC";
        font-weight: 400;
        font-size: 10px;

        .starts {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .homeAndSport {
      margin-top: 6px;
      .smallYlc {
        border-radius: 4px 4px 0 0;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px 4px 0 0;
        }
      }
      .smallTy {
        border-radius: 0 0 4px 4px !important;
        img {
          border-radius: 0 0 4px 4px !important;
        }
      }
    }

    .listOut {
      margin-top: 5px;
      border-radius: 4px;
      overflow: hidden;
      .listLine {
        background: rgba(26, 44, 56, 1);
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: flex-start;
        padding-left: 15px;
        position: relative;
        .listLeftImg {
          width: 14px;
          height: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .listName {
          color: rgba(255, 255, 255, 1);
          font-family: "PingFang SC";
          font-weight: 400;
          font-size: 14px;
          line-height: 45px;
        }
        .infoNum{
                display: flex;
                padding: 0px 5px;
                align-items: center;
                gap: 10px;
                border-radius: 22px;
                background: linear-gradient(92deg, #0385FF 0%, #0359FF 100%);
                color: #FFF;
                font-family: "SF Pro Display";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                position: absolute;
                right: 15px;
                top: 0;
                bottom: 0;
                margin:  auto;
                height: 15px;
                line-height: 15px;
        }
      }
      .selectSid {
        background: rgba(33, 55, 67, 1);
      }
    }
  }

  .wide {
    padding: 16px;

    .myHead {
      height: auto;
      background: rgba(26, 44, 56, 1);
      padding-top: 24px;
      padding-bottom: 24px;

      .avatar {
        width: 60px;
        height: 60px;
      }
    }

    .myHead::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 204px;
      /* 圆角半径 */
      border: 0.5px solid transparent;
      /* 边框宽度和透明色 */
      border-image: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.1) 0%,
          rgba(102, 102, 102, 0) 100%
        )
        1;
      /* 渐变边框 */
      pointer-events: none;
      /* 确保伪元素不阻止点击 */
    }
    .listLine {
      gap: 10px;
    }
  }

  .narrow {
    padding: 16px 8px;

    .myHead {
      height: 44px;

      .avatar {
        width: 44px;
        height: 44px;
      }
    }
  }
}


.tycs{
  position: relative;
  transition: all 0.2s ease;
  .popzwkf{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 217px;
    height: 253px;
    position: absolute;
    z-index: 500;
    left: 80px;
    top: 0px;
    transition: all 0.2s ease;
    visibility: hidden;
    opacity: 0;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.tycs:hover .popzwkf{
  visibility: visible;
  transition: all 0.2s ease;
  opacity: 1;
}
.smallTy{
  position: relative;
  transition: all 0.2s ease;
  .popzwkf{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 217px;
    height: 253px;
    position: absolute;
    z-index: 500;
    left: 50px;
    top: 0px;
    transition: all 0.2s ease;
    visibility: hidden;
    opacity: 0;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.smallTy:hover .popzwkf{
  visibility: visible;
  transition: all 0.2s ease;
  opacity: 1;
}
</style>
