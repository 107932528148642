import { render, staticRenderFns } from "./leftHead.vue?vue&type=template&id=2d63727c&scoped=true"
import script from "./leftHead.vue?vue&type=script&lang=js"
export * from "./leftHead.vue?vue&type=script&lang=js"
import style0 from "./leftHead.vue?vue&type=style&index=0&id=2d63727c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d63727c",
  null
  
)

export default component.exports