var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"leftHead",style:({ width: _vm.isExpand ? '240px' : '60px' })},[_c('div',{staticClass:"headTop",style:({
      gap: _vm.isExpand ? '10px' : '0px',
    })},[_c('div',{staticClass:"threeTraverse",on:{"click":function($event){return _vm.expand()}}},[_c('img',{attrs:{"src":require("@/assets/newHead/three.webp"),"draggable":"false"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpand),expression:"isExpand"}],staticClass:"ylc",on:{"click":function($event){return _vm.$router.push('/')}}},[_c('img',{attrs:{"src":require(_vm.$route.path == '/'
            ? `@/assets/newHead/top/ylc1.webp`
            : `@/assets/newHead/top/ylc0.webp`),"draggable":"false"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpand),expression:"isExpand"}],staticClass:"ylc tycs"},[_c('img',{attrs:{"src":require("@/assets/newHead/top/ty0.webp"),"draggable":"false"}}),_vm._m(0)])]),_c('div',{staticClass:"leftBom",class:{ wide: _vm.isExpand, narrow: !_vm.isExpand }},[_c('div',{staticClass:"myHead"},[_c('div',{staticClass:"heads"},[_c('div',{staticClass:"avatar"},[(_vm.userType)?_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLogin),expression:"isLogin"}],attrs:{"src":_vm.userData.headImg,"draggable":"false"}}):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpand && _vm.isLogin),expression:"isExpand && isLogin"}],staticClass:"liveDemo"},[_vm._v(" "+_vm._s(`Lv.${_vm.myData.currLevel || 0}`)+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpand),expression:"isExpand"}],staticClass:"myName"},[_vm._v(_vm._s(_vm.userData.username))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpand),expression:"isExpand"}],staticClass:"myLine"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLogin),expression:"isLogin"}],staticClass:"starts"},[_c('img',{attrs:{"src":require("@/assets/newHead/start.webp"),"draggable":"false"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLogin),expression:"isLogin"}]},[_vm._v("这是您加入的第"+_vm._s(_vm.userData.registerDay)+"天")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isExpand),expression:"!isExpand"}],staticClass:"homeAndSport"},[_c('div',{staticClass:"smallYlc"},[_c('img',{attrs:{"src":require(_vm.$route.path == '/'
              ? `@/assets/newHead/top/smylc1.webp`
              : `@/assets/newHead/top/smylc0.webp`)}})]),_vm._m(1)]),_vm._l((_vm.sidebarList),function(item,index){return _c('div',{key:index,staticClass:"listOut"},_vm._l((item.chlidren),function(child,y){return _c('div',{key:index + y + 100,staticClass:"listLine",class:{ selectSid: _vm.$route.path == child.path },on:{"click":function($event){return _vm.goLink(child)}}},[_c('div',{staticClass:"listLeftImg"},[_c('img',{attrs:{"src":_vm.$route.path == child.path ? child.icons : child.icon,"draggable":"false"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpand),expression:"isExpand"}],staticClass:"listName"},[_vm._v(_vm._s(child.name))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(child.id == 8 && _vm.isExpand && _vm.noReadCount > 0),expression:"child.id == 8 && isExpand && noReadCount > 0"}],staticClass:"infoNum"},[_vm._v(" "+_vm._s(_vm.noReadCount + "+")+" ")])])}),0)})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popzwkf"},[_c('img',{attrs:{"src":require("@/assets/newHead/zwkf.webp"),"draggable":"false"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"smallYlc smallTy"},[_c('img',{attrs:{"src":require("@/assets/newHead/top/smty0.webp"),"draggable":"false"}}),_c('div',{staticClass:"popzwkf"},[_c('img',{attrs:{"src":require("@/assets/newHead/zwkf.webp"),"draggable":"false"}})])])
}]

export { render, staticRenderFns }