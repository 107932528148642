<template>
    <el-dialog class="withDepostDialog" :visible="depositShow" @update:visible="upLoginShow" :show-close="false">
        <div class="diaTitle">
            <div class="diaTit">充值</div>
            <img src="@/assets/postWith/titleColse.svg" @click="upLoginShow(false)"/>
        </div>
        <div class="contents">
            <div class="wbxqblm">
                <div class="title">
                    <div class="titleLeft">
                        <div class="blueVertical"></div>
                        充值方式
                    </div>
                    <div class="titRight">
                        {{ balance }}
                        <img @click="init" class="reload" :class="{ 'rotate': getUserLoading || getDepostitLoading }"
                            src="@/assets/postWith/sgya.svg" />
                    </div>
                </div>
                <div class="wayDemo">
                    <!-- :style="{ 'margin-right': (index + 1) % 4 !== 0 ? '8px' : 'auto' }" -->
                    <div class="lists" v-for="(item, index) in list" :key="index"
                        :class="{ 'active': actWay == item.id }"
                         @click="selectCode(item)">
                        <div class="topImg" v-show="item.preferential != 0">{{ '+' + item.preferential + '%' }}</div>
                        <img :src="item.icon" />
                        <div class="name">{{ item.name }}</div>
                        <div class="customBom"></div>
                    </div>
                </div>
            </div>

            <div class="wbxqblm">
                <div class="title">
                    <div class="titleLeft">
                        <div class="blueVertical"></div>充值金额
                        <div class="messageGreen"><img src="@/assets/postWith/greenLamp.svg" />温馨提示：请按照选定金额支付，以便为您快速核实！
                        </div>
                    </div>
                </div>
                <div class="intPost">
                    <div class="intLeft">
                        <img class="dollarImg" src="@/assets/postWith/dollar.svg" />
                        <el-input class="amoutInt" v-model="amount" type="number"
                            :placeholder="`限额${minAmount} ~ ${maxAmount}元`"></el-input>

                        <!-- <div class="colse" @click="colsePost"><img src="@/assets/postWith/colse.svg" /></div> -->
                    </div>

                </div>

                <div class="wayDemo">
                    <!--   -->
                    <div class="amountContent" v-for="(item, index) in amoList" :key="index + item"
                        :style="{ 'margin-right': (index + 1) % 7 !== 0 ? '10px' : 'auto' }"
                        @click="activeAmo(item, index)" :class="{ 'activeAmo': amountActive == index }">
                        {{ item }}
                    </div>
                </div>
            </div>

            <div class="ckydwt">
                <div class="encounter">
                    存款遇到问题？联系<img src="@/assets/login/service.svg" /><span>在线客服</span>
                </div>
                <van-button :disabled="!amount > 0 || subLoading || amount < minAmount || amount > maxAmount"
                    class="btn" :loading="subLoading" @click="submit">立即存款</van-button>
            </div>




        </div>
    </el-dialog>
</template>

<script>

import { depositChannel, userInfo, paymentCreate } from '@/request/api'

export default {
    components: {},
    name: "depositIndex",
    props: {
        depositShow: Boolean,
    },
    data() {
        return {
            actWay: 0,
            list: [],
            amount: '',
            amountActive: -1,
            amoList: [

            ],
            subLoading: false,
            getUserLoading: false,
            balance: 0,
            getDepostitLoading: false,
            amountCode: "",
            minAmount: "",
            maxAmount: "",
        }
    },
    mounted() {
        this.getDepositChannel();
        this.getUserInfo();
    },
    methods: {
        upLoginShow(newValue) {
            this.$emit('update:depositShow', newValue);
        },
        init() {
            this.getDepositChannel();
            this.getUserInfo();
            this.amountActive = -1;
            this.amount = "";
        },
        selectCode(item) {
            this.actWay = item.id
            this.amoList = item.amountList.split(',');
            this.amountActive = -1;
            this.amount = "";
            this.amountCode = item.code;
            this.minAmount = item.minAmount
            this.maxAmount = item.maxAmount
        },
        activeAmo(item, index) {
            this.amountActive = index;
            this.amount = item
        },
        colsePost() {
            this.amountActive = -1;
            this.amount = ''
        },
        submit() {

            this.subLoading = true;
            var opt = {
                amount: this.amount,
                code: this.amountCode
            }
            paymentCreate(opt).then((res) => {
                if (res.code == 0) {
                    this.$message.success('提交成功');
                    this.$store.commit("setLoadingShowTrue")
                    window.location.href = res.data.url
                    setTimeout(() => {
                        this.$store.commit("setLoadingShowFalse")
                    }, 5000);
                }
                this.subLoading = false;
            })
        },
        getUserInfo() {
            this.getUserLoading = true;
            userInfo().then((res) => {
                if (res.code == 0) {
                    console.log(res)
                    this.balance = res.data.balance
                    //this.userData = res.data
                }
                this.getUserLoading = false;
            })
        },
        getDepositChannel() {
            this.getDepostitLoading = true;
            depositChannel().then((res) => {
                if (res.code == 0) {
                    this.list = res.data
                    if (res.data.length > 0) {
                        this.actWay = res.data[0].id
                        this.amountCode = res.data[0].code
                        this.amoList = res.data[0].amountList.split(',');
                        this.minAmount = res.data[0].minAmount
                        this.maxAmount = res.data[0].maxAmount
                    }

                }
                this.getDepostitLoading = false;
            })
        },
    }
}
</script>

<style lang="less" scoped>
.withDepostDialog {
    /deep/.el-dialog {
        display: flex;
        width: 700px;

        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        border-radius: 10px;
        background: radial-gradient(133.65% 137.5% at -19.87% -5.42%, #02568E 6%, #13293B 61.18%, #152B3F 100%);

        .el-dialog__header {
            display: none;
        }

        .el-dialog__body {
            width: 100%;
            padding: 20px;
        }
    }

    .diaTitle {
        display: flex;
        justify-content: space-between;
        margin-bottom:14px;
        .diaTit {
            color: #FFF;
            font-family: "PingFang SC";
            font-size: 24px;
            font-style: normal;
            font-weight: 400;

        }

        img {
            width: 40px;
            height: 40px;
        }
    }

}

.contents {
    
    background-repeat: no-repeat;
    background-size: cover;

    .wbxqblm {
        width: 100%;
        display: flex;
        padding: 10px 20px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        border-radius: 10px;
        border: 0.5px solid rgba(255, 255, 255, 0.10);
        background: rgba(255, 255, 255, 0.04);
        margin-bottom: 14px;
    }

    .title {
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-content: center;
        justify-content: space-between;
        color: #FFF;
        font-family: "PingFang SC";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        margin-bottom:20px;

        .titleLeft {
            display: flex;
            align-items: center;

            .blueVertical {
                width: 4px;
                height: 32px;
                border-radius: 0px 8px 8px 0px;
                background: linear-gradient(92deg, #0385FF 0%, #0359FF 100%);
                margin-right: 10px;
            }

            .messageGreen {
                display: flex;
                padding: 5px 10px;
                align-items: center;
                gap: 5px;
                border-radius: 10px;
                background: rgba(44, 209, 88, 0.09);
                color: #2CD158;
                font-family: "PingFang SC";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                margin-left: 10px;
                img {
                width: 16px;
                height: 16px;
               
            }
            }

           
        }

        .titRight {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFF;
            text-align: right;
            font-family: DIN;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;


            img {
                width: 16px;
                height: 16px;
                margin-left: 3px;
            }
        }
    }

    .wayDemo {
        display: flex;
        width: 100%;
        margin: 0 auto;
        flex-wrap: wrap;
        gap:10px;
        .amountContent {
            width: 80px;
            height: 40px;
            flex-shrink: 0;
            border: 1px solid rgba(255, 255, 255, 0.09);
            color: #FFF;
            text-align: center;
            font-family: "SF Pro Display";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            border-radius: 10px;
            line-height: 40px;
            margin-top: 14px;
        }

        .activeAmo {
            border: #0385FF 1px solid;
            color: #0385FF;
        }

        .lists {
            width: 78px;
            height: 80px;
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.04);
            flex-shrink: 0;
            padding-top: 20px;
            position: relative;

            margin-bottom: 8px;

            .topImg {
                color: #FFF;
                font-family: DIN;
                font-size: 10px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                position: absolute;
                display: inline-flex;
                padding: 1px 4px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 7px 0px;
                background: linear-gradient(47deg, #EC8B32 -3.03%, #EC3232 84.85%);
                top: 0;
                left: 0;
            }

            img {
                width: 26px;
                height: 26px;
                margin: 0 auto;
            }

            .name {
                color: #FFF;
                text-align: center;
                font-family: "PingFang SC";
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                margin-top: 5px;
            }

            .customBom {
                opacity: 0;
                position: absolute;
                right: -1px;
                bottom: -0.5px;
                background-image: url('../../assets/postWith/enters.svg');
                background-repeat: no-repeat;
                background-size: cover;
                width: 31px;
                height: 26px;
            }
        }

        .active {
            background: #283E4C;
            border: 1px solid #0385FF;

            .customBom {
                opacity: 1 !important;
            }
        }

    }

    .intPost {

        height: 48px;
        display: flex;
        padding: 12px 12px 12px 48px;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.04);
        background: rgba(255, 255, 255, 0.09);
    
        margin-bottom: 6px;

        .intLeft {
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;

            .dollarImg {
                width: 48px;
                height: 48px;
                flex-shrink: 0;
                fill: #FF3C44;
                filter: drop-shadow(0px 8px 12px rgba(255, 60, 68, 0.40));
                position: absolute;
                left: -48px;
                top: 4px;
            }

            .amoutInt {
                /deep/.el-input__inner {
                    padding-left: 0;
                    background: transparent;
                    border: 0;
                    color: #fff;


                }

                /deep/.el-input__inner::-webkit-outer-spin-button,
                /deep/.el-input__inner::-webkit-inner-spin-button {
                    -webkit-appearance: none !important;
                    -moz-appearance: none !important;
                    -o-appearance: none !important;
                    -ms-appearance: none !important;
                    appearance: none !important;
                    margin: 0;
                }

                input[type="number"] {
                    -webkit-appearance: textfield;
                    -moz-appearance: textfield;
                    -o-appearance: textfield;
                    -ms-appearance: textfield;
                    appearance: textfield;
                }


            }

            .colse {
                width: 16px;
                height: 16px;
                display: flex;
                align-items: center;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .intRight {
            color: rgba(255, 255, 255, 0.45);
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }
    }



    .ckydwt {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .encounter {
            display: flex;
            align-items: center;
            white-space: nowrap;
            justify-content: center;

            img {
                margin: 0 5px 0 10px;
            }

            span {
                font-family: "PingFang SC";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                background: linear-gradient(180deg, #FFF -54.54%, #C4DEFF 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .btn {
            display: flex;
            width: 280px;
            height: 46px;
            padding: 4px 12px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            border: 0;
            background: linear-gradient(92deg, #0385FF 0%, #0359FF 100%);
            color: #FFF;
            text-align: center;
            font-family: "PingFang SC";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            /* 24px */
        }
    }
}

.reload {
    &.rotate {
        animation: spin 1s linear infinite;
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>